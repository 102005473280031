import _ from "lodash";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "View Load History", "search-by": "UserName,Comment", "ph-search-by": "Search by User ID, Comment", "has-active": false, "has-filter-inactive": false, "has-action": false } }, [_c("text-field", { key: "RecordID", attrs: { "data-index": "recordID", "title": "Record ID", "sorter": true } }), _c("text-field", { key: "RecordDateTime", attrs: { "data-index": "recordDateTime", "title": "Date/Time", "parse": _vm.parseDateTime, "sorter": true } }), _c("text-field", { key: "Comment", attrs: { "data-index": "comment", "title": "Comment", "sorter": true } }), _c("text-field", { key: "UserName", attrs: { "data-index": "userName", "title": "User ID", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var ViewLoadHistory_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".search-by-record-id[data-v-0b695a74]{min-width:160px}\n")();
const __vue2_script$1 = {
  name: "ViewLoadHistory",
  inject: ["crud"],
  computed: {
    filter() {
      return this.crud.getFilter(this.field);
    },
    value() {
      if (!this.filter) {
        return "";
      }
      return this.filter.value;
    }
  },
  methods: {
    parseDateTime(value) {
      if (!value)
        return "";
      return this.$moment.parseZone(value).format("DD/MM/YYYY hh:mm A");
    },
    onChange: _.debounce(function({ target: { value } }) {
      if (!value) {
        this.crud.deleteFilter("RecordID");
        this.crud.fetchList();
        return;
      }
      const val = {
        operator: "Eq",
        value
      };
      this.crud.setFilter("RecordID", val);
      this.crud.fetchList();
    }, 400)
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "0b695a74", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ViewLoadHistory = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "api-url": _vm.apiUrl, "name": "web-analytics.job-logs" } }, [_c("view-load-history")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ViewLoadHistory
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "profitability",
          title: "Profitability",
          path: ""
        },
        {
          key: "view-load-history",
          title: "View Load History",
          path: "/web-analytics/view-load-history"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
